import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-five'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import MaintenanceArea from '../containers/maintenance/maintenance-area'
import ContactArea from '../containers/global/contact-area/contact-two'

const MaintenancePage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Maintenance" />
    <Header/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Maintenance"
    />
    <main className="site-wrapper-reveal">
        <MaintenanceArea/>
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default MaintenancePage
 